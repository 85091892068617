<template lang="pug">
include ../../configs/template
div.authorizationPage
  v-card(v-if="!isLoading.form").mt-16.pa-0.authFormAs
    v-card-title.accent.d-flex.flex-row.justify-center.text-bold-600.v-card__title {{ checkRouteName }}
    form.pa-4
      +field('body.new_password', '"newPassword"', '["required"]')(:append-icon="isShowNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="isShowNewPassword ? 'text' : 'password'"
        @click:append="isShowNewPassword = !isShowNewPassword")
      +field('body.confirm_password', '"confirmPassword"', '["required", "notSameAsPassword"]')(:append-icon="isConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="isConfirmPassword ? 'text' : 'password'"
        @click:append="isConfirmPassword = !isConfirmPassword"
        @keyup.enter="changedPassword")
      v-btn(color="primary" @click="changedPassword" :loading="isLoading.btn") {{ $t('btn.save') }}
  v-progress-circular(
    v-else
    :size="70"
    :width="7"
    color="primary"
    indeterminate)
</template>
<script>
import { changePasswordUserWithToken } from '@/mixins/validationRules'
import { mapActions } from 'vuex'
// import processingError from '@/configs/processingError'
// import authorization from '@/plugins/auth'

export default {
  data () {
    return {
      isShowNewPassword: false,
      isConfirmPassword: false,
      body: {
        new_password: null,
        confirm_password: null
      },
      isLoading: {
        form: false,
        btn: false
      }
    }
  },
  computed: {
    checkRouteName () {
      return this.$route.name === 'create-password-by-token'
        ? this.$t('password-create') : this.$t('password-recovery')
    }
  },
  validations () { return changePasswordUserWithToken(this) },
  mounted () {
    this.checkAccessChangePassword()
  },
  methods: {
    ...mapActions(['checkPasswordRecoveryToken', 'changePasswordUserWithToken']),
    async checkAccessChangePassword () {
      this.isLoading.form = true
      if (this.$auth.isAuth()) {
        window.location.replace('/')
        return
      }
      const { token, uid } = this.$route.query
      const response = await this.checkPasswordRecoveryToken({ token, uid })
      if (![200, 201].includes(response.code)) {
        this.$router.resolve({ query: {} })
        this.$router.push('/login')
      }
      this.isLoading.form = false
    },
    async changedPassword () {
      let body = { ...this.body, ...this.$route.query }
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading.btn = true
      const response = await this.changePasswordUserWithToken(body)
      if ([400, 401].includes(response.code)) this.$router.push('/login')
      else {
        this.$notification.success(`notify.success.${this.$route.name === 'create-password-by-token' ? 'createPassword' : 'recoveryPassword'}`)
        this.$router.push('/login')
      }
      this.isLoading.btn = false
    }
  }
}
</script>
